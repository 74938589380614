import axios from "@/utils/axios";

export default {
  // 获取事故列表信息
  getAccidentList(status) {
    return axios.get("/accident/list/accident", { params: { status } });
  },
  // 事故续报
  accidentBriefing(data) {
    return axios.post(`/accident/add/accident/briefing`, data);
  }
};
