<template>
  <div class="accidentNextReport">
    <van-nav-bar
      title="事故续报"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="formBody">
      <van-form ref="form" label-width="20%">
        <van-cell-group>
          <van-field
            v-model="accidentForm.name"
            label="事故名称"
            placeholder="自动获取最新事故名称"
            is-link
            rows="1"
            autosize
            type="textarea"
            readonly
            @click="popupShow = true"
          />
          <van-field
            v-model="accidentForm.code"
            label="事故编码"
            readonly
            placeholder="自动获取对应事故编码"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="accidentForm.loss"
            type="number"
            name="经济损失"
            label="*经济损失"
            placeholder="请输入经济损失（万元）"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="accidentForm.radius"
            type="number"
            name="影响半径"
            label="*影响半径"
            placeholder="请输入影响半径（公里）"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="accidentForm.injuredNum"
            type="number"
            name="受伤人数"
            label="*受伤人数"
            placeholder="请输入受伤人数"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="accidentForm.missingNum"
            type="number"
            name="失踪人数"
            label="*失踪人数"
            placeholder="请输入失踪人数"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="accidentForm.deathNum"
            type="number"
            name="死亡人数"
            label="*死亡人数"
            placeholder="请输入死亡人数"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="accidentForm.trappedNum"
            type="number"
            name="受困人数"
            label="*受困人数"
            placeholder="请输入受困人数"
            :rules="[{ required: true }]"
          />
        </van-cell-group>
        <div class="blocktitle">*损失程度描述</div>
        <van-cell-group>
          <van-field
            v-model="accidentForm.description"
            rows="2"
            autosize
            name="损失程度描述"
            type="textarea"
            maxlength="50"
            placeholder="请输入事故描述"
            show-word-limit
            :rules="[{ required: true }]"
          />
        </van-cell-group>
        <div class="blocktitle">其他说明</div>
        <van-cell-group>
          <van-field
            v-model="accidentForm.remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入备注信息"
            show-word-limit
          />
        </van-cell-group>
        <!--        <div class="blocktitle">附件（支持上传图片、视频、录音）</div>-->
        <!--        <div class="filecon">-->
        <!--          <FileUploader-->
        <!--            v-model="fileList"-->
        <!--            :max-count="5"-->
        <!--            :unable-upload="false"-->
        <!--            :accept="FILE_ACCEPT_TYPES.IMAGE"-->
        <!--          />-->
        <!--        </div>-->
        <div style="margin-top: 36px;padding: 0 16px;">
          <div
            class="btn look"
            :style="{ opacity: accidentForm.accidentId ? 1 : 0.4 }"
            @click="onSubmit"
          >
            提交
          </div>
        </div>
      </van-form>
    </div>
    <van-popup v-model="popupShow" position="bottom">
      <div class="popupTitle">事故列表</div>
      <div class="accidentList">
        <van-cell v-for="item in accidentList" :key="item.id">
          <div class="accidentItem" @click="changeAccident(item)">
            <div class="content">{{ item.name }}</div>
            <van-icon
              :name="item.id === accidentForm.accidentId ? 'checked' : 'circle'"
              size="24"
            ></van-icon>
          </div>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { Toast } from "vant";
import api from "./api";
export default {
  name: "AccidentNextReport",
  components: {
    // FileUploader
  },
  data() {
    return {
      accidentForm: {
        accidentId: "",
        name: "",
        code: "",
        loss: "", // 经济损失(万元)
        radius: "", // 影响半径(公里)
        injuredNum: "", // 受伤人数(人)
        missingNum: "", // 失踪人数(人)
        deathNum: "", // 死亡人数(人)
        trappedNum: "", // 受困人数(人)
        description: "", // 损失程度描述
        remark: "" // 其他说明
      },
      popupShow: false, // 事故列表弹框
      accidentList: [], // 事故列表
      FILE_ACCEPT_TYPES
    };
  },
  created() {
    this.getAccidentList();
  },
  methods: {
    // 获取事故列表
    getAccidentList() {
      api.getAccidentList("1,2,3").then(data => {
        this.accidentList = data || [];
        if (data && data.length > 0) {
          this.changeAccident(data[0]);
        } else {
          Toast("暂无事故");
        }
      });
    },
    // 切换事故
    changeAccident(val) {
      this.popupShow = false;
      for (let key in this.accidentForm) {
        this.accidentForm[key] = "";
      }
      this.accidentForm.accidentId = val.id;
      this.accidentForm.name = val.name;
      this.accidentForm.code = val.code;
    },
    onClickLeft() {
      history.go(-1);
    },
    onSubmit() {
      if (!this.accidentForm.accidentId) return;
      this.$refs.form
        .validate()
        .then(async data => {
          api.accidentBriefing(this.accidentForm).then(data => {
            Toast("提交成功");
            history.go(-1);
          });
        })
        .catch(err => {
          console.log(err);
          if (err && err.length > 0) {
            Toast(err[0].name + "不能为空");
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.accidentNextReport {
  height: 100%;
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .formBody {
    height: calc(100% - 61px);
    overflow-y: scroll;
    padding-top: 16px;
    padding-bottom: 100px;
    background: #f7f8fa;
    .blocktitle {
      padding-left: 16px;
      font-size: 14px;
      color: #646566;
      margin-bottom: 8px;
    }
    .filecon {
      padding: 14px;
      padding-left: 16px;
      background: #fff;
    }
    .btn {
      border-radius: 22px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      text-align: center;
    }
    .look {
      background: linear-gradient(180deg, #1677ff 0%, #62a6f8 100%);
      color: #ffffff;
      margin-bottom: 18px;
    }
    .submit {
      border: 1px solid #3e8dfe;
      background: #ffffff;
      color: #3e8dfe;
    }
  }
  .van-cell-group {
    margin-bottom: 18px;
  }
  .van-switch {
    margin-left: 80%;
  }
  .popupTitle {
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    color: #333333;
    font-weight: bold;
  }
  .accidentList {
    height: 60vh;
    overflow-y: scroll;
    .accidentItem {
      display: flex;
      align-items: center;
      .content {
        color: #333333;
      }
      .van-icon {
        margin-left: 10px;
        &.van-icon-checked {
          color: #1676ff;
        }
      }
    }
  }
}
</style>
